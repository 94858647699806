<template>
  <div>
    <v-card>
      <v-toolbar>
        <v-card-title>Pages</v-card-title>
        <v-spacer></v-spacer>
        <v-btn
          :color="Pallette.actionButtons.newItem"
          fab
          dark
          absolute
          bottom
          right
          :small="!$vuetify.breakpoint.smAndUp"
          to="/settings/page/create"
          data-testid="btn-create-page"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <DataTableExtended
          ref="refTablePages"
          :headers="headers"
          :items="pagesList"
          sortable
          class="elevation-0 border pt-3"
          :loading="showTableLoader"
          :no-data-text="showTableLoader ? 'Loading…' : 'No data available'"
          v-on:init-table-data="getPagesList"
          data-testid="table-pages"
        >
          <template v-slot:top>
            <v-row class="px-3">
              <v-col cols="12" md="3"> </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.data.name`]="{ item }">
            <span data-testid="page-title">
              {{ item.title }}
            </span>
          </template>
          <template v-slot:[`item.content`]="{ item }">
            <div v-html="item.content" data-testid="page-content"></div>
          </template>
          <template v-slot:[`item.menu`]="{ item }">
            <div
              class="text-center d-flex align-center justify-space-around"
              v-if="$vuetify.breakpoint.mdAndUp"
            >
              <v-tooltip
                top
                v-if="$can('update', 'pages') || $can('update', 'Superadmin')"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    fab
                    dark
                    x-small
                    :color="Pallette.actionButtons.edit"
                    v-on="on"
                    :to="`/settings/page/edit/${item._key}`"
                    class="mx-1"
                    data-testid="btn-edit-page"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit download</span>
              </v-tooltip>

              <v-tooltip
                top
                v-if="$can('delete', 'pages') || $can('delete', 'Superadmin')"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    fab
                    dark
                    x-small
                    :color="Pallette.actionButtons.delete"
                    v-on="on"
                    @click="deletePage(item)"
                    class="mx-1"
                    data-testid="btn-delete-page"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete page</span>
              </v-tooltip>
            </div>
          </template>
        </DataTableExtended>
      </v-card-text>
      <Confirm ref="confirmDialog"></Confirm>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import Confirm from "@/components/Confirm"
import DataTableExtended from "@/components/table/DataTableExtended"

export default {
  components: {
    Confirm,
    DataTableExtended,
  },

  data: () => ({
    headers: [
      {
        text: "Title",
        align: "left",
        value: "title",
        width: 200,
        sortable: true,
      },
      {
        text: "Content",
        align: "left",
        value: "content",
        sortable: true,
      },
      {
        text: "",
        value: "menu",
        align: "center",
        sortable: false,
        class: "th-clear-padding",
        width: 100,
      },
    ],
  }),

  methods: {
    getPagesList() {
      let self = this
      let getParams = self.$refs.refTablePages.getTableServerParams()
      let fetchData = {
        params: getParams,
      }
      self.$store.dispatch("pagesList", fetchData).then(() => {})
    },
    deletePage(item) {
      let self = this
      self.$refs.confirmDialog
        .open("Delete", "Are you sure you want to delete this page?", {
          color: "red darken-4",
          width: 330,
        })
        .then((confirm) => {
          if (confirm) {
            self.$store.dispatch("saveTableLoaderAction", true)
            self.$store.dispatch("deletePage", item).then(({ data }) => {
              self.fetchTableData()
              self.$store.dispatch("saveTableLoaderAction", false)
            })
          }
        })
    },
  },

  computed: {
    ...mapGetters({
      showTableLoader: "getShowTableLoader",
      Pallette: "get_Pallette",
      pagesList: "pagesList",
    }),
  },
}
</script>
